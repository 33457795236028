import avatar from '../assets/images/douglas-lopes/douglas-lopes.jpg';
import audio from '../assets/sounds/douglas-lopes.mp3';

import douglas01 from '../assets/images/douglas-lopes/DouglasLopes_01.jpg';
import douglas02 from '../assets/images/douglas-lopes/DouglasLopes_02.jpg';
import douglas03 from '../assets/images/douglas-lopes/DouglasLopes_03.jpg';
import douglas04 from '../assets/images/douglas-lopes/DouglasLopes_04.jpg';
import douglas05 from '../assets/images/douglas-lopes/DouglasLopes_05.jpg';
import douglas06 from '../assets/images/douglas-lopes/DouglasLopes_06.jpg';
import douglas07 from '../assets/images/douglas-lopes/DouglasLopes_07.jpg';
import douglas08 from '../assets/images/douglas-lopes/DouglasLopes_08.jpg';
import douglas09 from '../assets/images/douglas-lopes/DouglasLopes_09.jpg';
import douglas10 from '../assets/images/douglas-lopes/DouglasLopes_10.jpg';
import douglas11 from '../assets/images/douglas-lopes/DouglasLopes_11.jpg';

const douglasData = {
    avatar,
    audio,
    douglas01,
    douglas02,
    douglas03,
    douglas04,
    douglas05,
    douglas06,
    douglas07,
    douglas08,
    douglas09,
    douglas10,
    douglas11
}

export default douglasData

